import { defineStore } from "pinia";
import axios from 'axios'
import { useRuntimeConfig } from "nuxt/app";
import helper from "~/resources/js/libraries/helper";

export const useListingStore = defineStore("listings", {
	state: () => ({
		categories: [],
        form: {},
        selected_form: {
            features: {}
        } as { [key: string]: any },
        errors: [] as any[],
        addedListing: {}
	}),
    getters: {
		categoriesGetter: (state) => () => state.categories,
        formGetter: (state) => () => state.form,
        selectedFromGetter: (state) => () => state.selected_form,
        errorsGetter: (state) => () => state.errors,
        addedListingGetter: (state) => () => state.addedListing
    },
	actions: {
        getVcrmAccountToken() {
			if (!document) {
				return false;
			}
			const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
			if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
				return vue_cookie.split("=")[1];
			}
		},

        async fetchAddListingForm(category_id: string) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
            
            if (!vue_cookie) {
				return false;				
			}

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/listings/${category_id}/form`)
                .then((res) => {
                    this.form = res.data?.[0] || {}
                    const features = res.data?.[0]?.features || []
                    // for (let feature of features) {
                    //     this.selected_form[feature['listing_field']] = feature.data_source?.[0]?.name || ''
                    // }
                })
                .catch(err => {
                    this.form = {}
                })
        },

        async fetchListingPreview() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;
            const mediaUrl = config.public.mediaUrl;
            const vue_cookie = this.getVcrmAccountToken();

            const listing_id = helper.localStorage.getData('listing_id')
            
            if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';
            
            await axios.get(`${accountApiUrl}/listings/${listing_id}/details`)
                .then((res) => {
                    const listing = res.data
                    const gallery_pictures = listing['gallery_pictures']
                    if (gallery_pictures.length) {
                        listing['gallery_pictures'] = gallery_pictures.map((p: any) => `${mediaUrl}/${p}`)
                    }
                    const picture = listing['picture']
                    if (picture) {
                        listing[picture] = `${mediaUrl}/${picture}`
                    }
                    this.addedListing = listing
                })
                .catch(err => {
                    this.addedListing = []
                })
        },

        deleteAddListingForm() {
            this.form = {}
        },

        updateSelectedForm(key: string, value: string) {
            this.selected_form[key] = value
        },

        updateFeature(key: string, value: string) {
            if (!this.selected_form.features) {
                this.selected_form.features = {}
            }
            this.selected_form.features[key] = value;
        },

        updateSelectedMedia(values: string[]) {
            this.selected_form['gallery_pictures'] = values;
            if (values?.[0]) {
                this.selected_form['picture'] = values?.[0]
            } 
        },

        async addListing() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
            
            if (!vue_cookie) {
				return false;				
			}

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            return await axios.post(`${accountApiUrl}/listings`, this.selected_form)
                .then(res => {
                    helper.localStorage.setData('listing_id', res.data.id)
                    return res
                })
        },

        setAddListingErrors(errors: any[]) {
            this.errors = errors
        },

        saveListingInLocalStorage() {
            helper.localStorage.setData('listing', JSON.stringify(this.selected_form))
        },

        getListingPreviewFromLocalStorage() {
            const listing = helper.localStorage.getData('listing')
            return listing ? JSON.parse(listing) : this.selected_form || {};
        },

        async fetchCategories() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
            
            if (!vue_cookie) {
				return false;				
			}

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/lists/categories`)
                .then((res) => {
                    this.categories = res.data
                })
                .catch(err => {
                    this.categories = []
                })
        },

        resetCategories() {
            this.categories = []
        }

    }
});